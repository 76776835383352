import React from "react";
import "../Styles/footer.css"
export default function Footer() {
  return (
    <footer id="footer">
      Made with Love. Made with React @yashwanthkorla @2019. All Rights
      Reserved.
    </footer>
  );
}
