import React from "react";
import "../Styles/contact.css";

export default function Contact() {
  return (
    <section id="contactSection">
      <div id="contactContainer">
        <p id="ContactHeader">CONTACT</p>
        <p id="contactDescription">
          If u want an App / Website or if u want to include me or hire me in to
          your project, use the below to connect with me
        </p>
        <div id="details">
          <div id="emails">
            <p className="miniDetails_heading">Email Id's</p>
            <p className="miniDetails">yashwanthkorla@gmail.com</p>
            <p className="miniDetails">yashwanthdeveloper@gmail.com</p>
          </div>
          <div id="othermodes">
            <p className="miniDetails_heading">Phone Number</p>
            <p className="miniDetails">(+91) - 8074933970</p>
            <p className="miniDetails">(+91) - 9848487869</p>
          </div>
        </div>
      </div>
    </section>
  );
}
