import React from "react";
import "../Styles/project.css";
import loadingGif from "../Images/loader.gif";
import { apps } from "../data/apps";
export default class Projects extends React.Component {
  state = {
    repos: [],
    loading: true,
    status: "",
    app: apps
  };

  componentDidMount() {
    fetch("https://api.github.com/users/yashwanthkorla/repos")
      .then(res => res.json())
      .then(data => {
        const new_data = data.filter(ob => ob["fork"] === false);
        this.setState({
          repos: new_data,
          loading: false
        });
      });
  }
  render() {
    return (
      <section id="projectSection">
        <div id="projectContainer">
          <p id="projectContainer_Heading">APPS & PROJECTS</p>
          <div id="Apps">
            <h2 style={{ padding: "10px 0" }}>Apps on PlayStore</h2>
            <div id="appsContainer">
              {Object.keys(apps).map(el => (
                <div key={el} className="appOnStore">
                  <img
                    className="appImage"
                    src={require(`../Images/${
                      this.state.app[el]["image"]
                    }.webp`)}
                    alt={this.state.app[el]["image"]}
                  />
                  <div className="appDetails">
                    <p className="appName">{el}</p>
                    <p className="appCategory">
                      {this.state.app[el]["category"]}
                    </p>
                    <p className="appDescription">
                      {this.state.app[el]["Description"]}
                    </p>
                    <p className="appLink">
                      <a
                        href={this.state.app[el]["url"]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        App Link
                      </a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="projectsOnGit">
            <h2>Projects On GitHub</h2>
            <div id="projectRepo_main">
              {this.state.loading ? (
                <img id="loader_image" src={loadingGif} alt="Loading" />
              ) : (
                <div id="mainProjectRepoContainer">
                  {this.state.repos.map((el) => (
                    <div className="singleRepoProject" key={el['name']}>
                      <p className="singleRepoName">{el['name'].replace(/-|_/g," ").toUpperCase()}</p>
                      <p className="singleRepoDescription">{el['description']}</p>
                      <p className="repoLink"><a href={el['html_url']} target="_blank" rel="noopener noreferrer" >View On Github</a></p>
                      </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
