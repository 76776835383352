import React from "react";
import "../Styles/about.css";
export default function Aboutme() {
  return (
    <section id="aboutSection">
      <div id="about_container">
        {/* <div id="aboutme_firstPart">
          <img src={Avatar} />
        </div> */}
        <div id="aboutme_secondPart">
          <p id="aboutme_heading">ABOUT ME</p>
          <p id="whoamI_aboutme">WHO AM I</p>
          <p id="aboutme_description">
            I am from <b>INDIA</b>. I completed my graduation in Mechanical
            Engineering from <b>CBIT</b> (Chaitanya Bharathi Institute of
            Technology), Hyderabad. Though I am a Mechanical Engineer, i love
            programming. My journey started with blogging and currently i am a{" "}
            <b>Mobile Web Specialist</b> who has developed several mobile apps
            for different mobile platforms. I use JavaScript a lot in my daily
            life to develop hybrid mobile apps and websites. In 2018, I fell in
            love with AI and started exploring. I love to work with AI, my
            GitHub profile would give you a glimpse of what I have been up to
            for the past few years.
          </p>
          <div id="specialization">
            <div id="mobile_web">
              {/* <p className="avatar_aboutme">MW</p> */}
              <div>
                <p className="specializationName">Mobile Web Specialist</p>
                <p className="certificate_link">
                  <a
                    href="https://confirm.udacity.com/Q2QDYKCK"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Certificate
                  </a>
                </p>
              </div>
            </div>
            <div id="machine_learning">
              {/* <p className="avatar_aboutme">AI</p> */}
              <div>
                <p className="specializationName">Machine Learning</p>
                <p className="certificate_link">
                  <a
                    href="https://www.coursera.org/account/accomplishments/records/SN8WEU2HSCKT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Certificate
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
