export const apps = {
  WordsApp: {
    url:
      "https://play.google.com/store/apps/details?id=wordsapp.mukul.code2pass&hl=en",
    image: "wordsapp",
    category: "Education",
    Description: "GRE Vocabulary builder App"
  },
  WhenNWhere: {
    url:
      "https://play.google.com/store/apps/details?id=com.mukulpathak.wnw&hl=en",
    category: "Productivity",
    image: "whennwhere",
    Description: "Stuff Tracking App"
  }
};
