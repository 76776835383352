import React from "react";
import "../Styles/skills.css";
import { skillsData } from "../data/skillsData";
export default class IntroSection extends React.Component {
  state = {
    skillsKeys: Object.keys(skillsData)
  };
  render() {
    return (
      <section id="skillsSection">
        <div id="skillSectionContainer">
          <p id="mySkillsHeading">MY SKILLS</p>
          <p id="skillDescription">
            I Love to Learn new Tech/Programming Languages. Below are few skills
            which i use more often.
          </p>
          <div id="skillsHolder">
            {this.state.skillsKeys.map(el => (
              <div className="Skillcontainer" key={el}>
                <p className="skillName">{el}</p>
                <div className="skillbar">
                  <p className="skills" style={{ width: skillsData[el] + "%" }}>
                    <span className="skillPercent">{skillsData[el] + "%"}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
