import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Styles/index.css'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

window.onscroll = function() {stickyNav()};
let navbar = document.getElementById("navbar-container");
let sticky = navbar.offsetTop;
function stickyNav() {
  if (window.pageYOffset > sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
