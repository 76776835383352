import React from "react";
import Navbar from "./Components/Navbar";
import IntroSection from "./Components/IntroSection";
import Aboutme from "./Components/Aboutme";
import Skills from "./Components/Skills";
import Projects from "./Components/Projects";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import './Styles/app.css'

function App() {
  return (
    <div>
      <Navbar />
      <IntroSection />
      <Aboutme />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}



export default App;
