import React from "react";
import "../Styles/intro.css";
import Avatar from "../Images/me.jpg";
export default function IntroSection() {
  return (
    <section id="intro">
      <div id="intro_container">
        <div id="firstPart">
          <div id="intro_name">
            <p id="light_hello">Hello, I'm</p>
            <p id="myName">Yashwanth Korla</p>
          </div>
          <p className="software_engineer">
            Mobile Web Specialist | Javascript | Python | ML/DL Developer
          </p>
          <div id="shareButtons">
            <a
              id="linkedIn"
              href="https://in.linkedin.com/in/korlayashwanth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>LinkedIn</p>
            </a>
            <a
              id="gitHub"
              href="https://github.com/yashwanthkorla"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Github</p>
            </a>
          </div>
          <div id="actionButtons">
            <a href="#contactSection" id="hireme">
              <p>Hire me</p>
            </a>
            <a
              id="resume"
              href="https://drive.google.com/open?id=1xvEP3h-4Jl9WY0kJYzhuHZp9lMXiZvWA"
              rel="noopener noreferrer"
              target="_blank"
            >
              <p>View my Resume</p>
            </a>
          </div>
          <div id="blogs">
            <p id="blogHeading">Blogs : </p>
            <p className="blogLink">
              <a href="http://techyknights.com" rel="noopener noreferrer">
                Techyknights
              </a>
            </p>
            <p className="blogLink">
              <a href="http://worldtech360.blogspot.com" rel="noopener noreferrer">
                Worldtech360
              </a>
            </p>
          </div>
        </div>
        <div id="secondPart">
          <img src={Avatar} alt="My Pic" />
        </div>
      </div>
    </section>
  );
}
