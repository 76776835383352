import React from "react";
import "../Styles/navbar.css";

export default class Navbar extends React.Component {
  navbarHiding = e => {
    e.preventDefault();
    let navbar = document.getElementById("navbar");
    console.log("clicked")
    if (navbar.style.display !== "block") {
      navbar.style.display = "block";
    }
    else{
      navbar.style.display = "none";
    }
  };
  render() {
    return (
      <nav id="navbar-container">
        <h1 id="header-left-section">
          <a href="/">
            Yashwanth Korla{" "}
            <span
              onClick={e => {
                this.navbarHiding(e);
              }}
              id="icon"
            >
              ☰
            </span>
          </a>
        </h1>
        <ul id="navbar">
          <li>
            <a href="#intro">Home</a>
          </li>
          <li>
            <a href="#aboutSection">About Me</a>
          </li>
          <li>
            <a href="#skillsSection">Skills</a>
          </li>
          <li>
            <a href="#projectSection">Project</a>
          </li>
          <li>
            <a href="#contactSection">Contact</a>
          </li>
        </ul>
      </nav>
    );
  }
}
